<template>
  <div class="userList">
    <div class="page-title">{{ $t('pageTitle.users') }}</div>
    <div class="actionBar">
      <div class="actionButton bgBrown refresh" @click="loadUsers"><i class="fas fa-sync"></i>
        <div class='actionButtonTitle'>{{ $t('general.refresh') }}</div>
      </div>
    </div>
    <div class="topNav">
      <router-link to="/settings"><div class='navButton'><i class="fas fa-arrow-left"/> {{ $t('general.back') }}</div></router-link>
    </div>
    <div class="screen-container" ref="screenContainer" :style='{ height: screenContainerHeight }'>
      <div class="listWrapper">
        <EmptyListAnimationComponent v-if="users != null && users.length == 0"/>
        <div class="listItemWrapper" v-for="entry in users" :key="entry._id">
          <div class="listItem user" @click="editUser(entry)"><div class="icon"><i class="far fa-user"/></div>
            {{entry.firstname}} {{entry.lastname}} ({{entry.email}})<span class="is-admin-marker" title="Administrator" v-if="entry.isAdmin">&nbsp;<i class="fas fa-hat-wizard"/></span>
          </div>
          <div class="listActionWrapper">
            <span class="listAction userAction" v-if="entry.editable" @click="editUser(entry)"><i class="fas fa-pen"/></span>
            <span class="listAction userAction" @click="deleteUser(entry._id)"><i class="fas fa-trash"/></span>
          </div>
        </div>
      </div>
    </div>
    <div class="floating-add-button" @click="addUser">
      <img class="floating-add-button-icon" src="../assets/icons/plus_white.png" />
    </div>
    <CreateOrEditUserModal :userToEdit="userToEdit" ref="createOrEditUserModal" @refresh="loadUsers"/>
    <ConfirmModal ref="confirmDeleteModal" :title="$t('modal.titleDelete')" :text="$t('modal.textDelete')"/>
  </div>
</template>

<script>
/* eslint-disable no-console,no-alert,no-underscore-dangle */

import ServiceHelper from '@/helpers/ServiceHelper';
import CryptoHelper from '@/helpers/CryptoHelper';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import ScreenHeightMixin from '@/mixins/ScreenHeightMixin.vue';
import EmptyListAnimationComponent from '@/components/EmptyListAnimationComponent.vue';

import CreateOrEditUserModal from '@/components/modals/CreateOrEditUserModal.vue';

export default {
  name: 'UserListComponent',
  props: [],
  mixins: [ScreenHeightMixin],
  components: {
    CreateOrEditUserModal,
    ConfirmModal,
    EmptyListAnimationComponent,
  },
  data() {
    return {
      users: [],
      userToEdit: null,
    };
  },
  watch: {
  },
  async mounted() {
    this.loadUsers();
  },
  methods: {
    async loadUsers() {
      this.users = [];
      try {
        const data = await ServiceHelper.loadDataFromBackend('users');
        data.payload.forEach((entry) => {
          const e = entry;
          // if (e.firstname !== 'admin') {
          e.editable = true;
          if (e.firstname != null) e.firstname = CryptoHelper.decrypt(e.firstname).toString('utf-8');
          if (e.lastname != null) e.lastname = CryptoHelper.decrypt(e.lastname).toString('utf-8');
          if (e.email != null) e.email = CryptoHelper.decrypt(e.email).toString('utf-8');
          /*
          } else {
            e.editable = false;
          }
          */
        });
        this.users = data.payload;
      } catch (error) {
        console.error(error);
      }
      return null;
    },
    async addUser() {
      this.userToEdit = { userGroups: [] };
      this.$refs.createOrEditUserModal.show();
      return null;
    },
    async deleteUser(userId) {
      if (await this.$refs.confirmDeleteModal.show()) {
        try {
          const url = `user/${userId}`;
          await ServiceHelper.deleteDataFromBackend(url);
          this.loadUsers();
        } catch (error) {
          console.error(error);
          if (error.errorCode && parseInt(error.errorCode, 10) > -1) {
            alert(this.$t(`error.code_${error.errorCode}`));
          }
        }
      }
      return null;
    },
    async editUser(user) {
      if (user.editable) {
        this.userToEdit = JSON.parse(JSON.stringify(user));
        this.$refs.createOrEditUserModal.show();
      }
      return null;
    },
  },
  computed: {
  },
};
</script>

<style scoped>
@import url('../styles/list.module.css');

.listWrapper .listItemWrapper:nth-child(odd) {
  background-color: var(--color_list_1);
}
.listWrapper .listItemWrapper:nth-child(even) {
  background-color: var(--color_list_2);
}

.is-admin-marker {
  color: #aaaaaa;
}
</style>
