<template>
  <UserListComponent/>
</template>

<script>
import UserListComponent from '@/components/UserListComponent.vue';

export default {
  name: 'UsersView',
  components: {
    UserListComponent,
  },
  props: [],
  mounted() {

  },
};
</script>
