<template>
  <CustomModal v-model="showUserModal" @confirm="confirm" @cancel="cancel">
    <template v-slot:title>{{user != null && user._id != null ? $t('modal.titleEditUser') : $t('modal.titleCreateUser')}}</template>
    <div class="form" v-if="user != null && groupsById != null">
      <div class="row" >
        <div class="label">{{ $t('modal.labelFirstname') }}:</div>
        <div class="value"><input @keyup.enter="confirm" @blur="checkForm" v-model="user.firstname" type="text"/>
          <br/><div v-if="errorFirstname != null" class="fieldError">{{errorFirstname}}</div>
        </div>
      </div>
      <div class="row">
        <div class="label">{{ $t('modal.labelLastname') }}:</div>
        <div class="value"><input @keyup.enter="confirm" @blur="checkForm" v-model="user.lastname" type="text"/>
          <br/><div v-if="errorLastname != null" class="fieldError">{{errorLastname}}</div>
        </div>
      </div>
      <div class="row">
        <div class="label">{{ $t('modal.labelUsername') }}:</div>
        <div class="value"><input v-model="user.email" type="text" @blur="checkEmail()"/>
          <br/><div v-if="errorEmail != null" class="fieldError">{{errorEmail}}</div>
          <div v-if="errorInvalidEmail != null" class="fieldError">{{errorInvalidEmail}}</div>
        </div>
      </div>
      <div class="row">
        <div class="label">{{ $t('modal.labelPassword') }}:</div>
        <div class="value"><input @keyup.enter="confirm" @blur="checkForm" v-model="user.password" :type="showPassword ? 'text' : 'password'"/>
          <div v-if="user.password != null && user.password.length > 0 && showPassword" class="showPassword" @click="showPassword = !showPassword"><i class="fas fa-eye"/></div>
          <div v-if="user.password != null && user.password.length > 0 && !showPassword" class="showPassword" @click="showPassword = !showPassword"><i class="fas fa-eye-slash"/></div>
          <br/><div v-if="errorEmptyOrInsufficientPassword != null" class="fieldError">{{errorEmptyOrInsufficientPassword}}</div>
        </div>
      </div>
      <div class="row" v-if="user.password != null && user.password.length > 0">
        <div class="label">{{ $t('modal.labelPasswordConfirmation') }}:</div>
        <div class="value"><input @keyup.enter="confirm" @blur="checkForm" v-model="user.passwordConfirmation" :type="showPassword ? 'text' : 'password'"/>
          <br/><div v-if="errorPasswordsDontMatch != null" class="fieldError">{{errorPasswordsDontMatch}}</div>
        </div>
      </div>
      <div class="row">
        <div class="label">{{ $t('modal.labelIsAdmin') }}:</div>
        <div class="value"><input v-model="user.isAdmin" type="checkbox"/></div>
      </div>
      <div class="row" v-if="!user.isAdmin">
        <div class="label">{{ $t('modal.labelProductPermissions') }}:</div>
        <div class="value">
          <input v-model="permissions.web" type="checkbox" class="inline" data-cy='user-permission-web'/> Web<br/>
          <input v-model="permissions.colada" type="checkbox" class="inline" data-cy='user-permission-app'/> Collada App<br/>
          </div>
      </div>

      <div class="row">
        <div class="label">{{ $t('modal.labelUsergroups') }}</div>
        <div class="value">
          <div class='tagsInput'>
            <div class="tagSelection" v-if="showGroupOptions && proposedGroups.length > 0">
              <div class="tagOption" data-cy='user-group-option' @click="addGroup(g)" v-for="g in proposedGroups" :key="g._id">{{g.name}}</div>
            </div>
            <div class="tag" v-for="g in user.userGroups" :key="g" >{{getGroupName(g)}} <div @click="removeGroup(g)" class="delete">x</div> </div>
            <input v-model="groupQuery" data-cy='user-group-query' @keyup="proposeGroups" type="text" :placeholder="$t('modal.labelGroupName')"/>
          </div>
        </div>
      </div>
    </div>
  </CustomModal>
</template>

<script>
/* eslint-disable no-console,no-alert,no-underscore-dangle,no-bitwise */

import CustomModal from '@/components/modals/CustomModal.vue';
import ServiceHelper from '@/helpers/ServiceHelper';
import CryptoHelper from '@/helpers/CryptoHelper';
import { sha256 } from 'js-sha256';

export default {
  name: 'CreateOrEditUserModal',
  props: ['userToEdit'],
  components: {
    CustomModal,
  },
  data() {
    return {
      showUserModal: false,
      user: null,
      groupQuery: '',
      groups: [],
      groupsById: null,
      proposedGroups: [],
      showGroupOptions: true,
      errorFirstname: null,
      errorLastname: null,
      errorEmail: null,
      errorEmptyOrInsufficientPassword: null,
      errorPasswordsDontMatch: null,
      errorInvalidEmail: null,
      showPassword: false,
      emailChecked: true,
      emailOnEnter: null,
      permissions: {},
      /* eslint-disable no-useless-escape */
      strongRegex: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\(\)\-\._!@#\$%\^&\*\|])(?=.{8,})'),
      /* eslint-disable no-useless-escape */
      mediumRegex: new RegExp('^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})'),
    };
  },
  watch: {
    userToEdit(newVal) {
      this.user = newVal;
      this.emailOnEnter = this.user != null && this.user.email != null ? this.user.email.toLowerCase() : null;
    },
  },
  mounted() {
    this.user = this.userToEdit;
    this.fetchGroups();
    this.updatePermissions();
  },
  methods: {
    getGroupName(g) {
      if (this.groupsById != null && this.groupsById[g] != null) {
        return this.groupsById[g].name;
      }
      return 'unknown';
    },
    async show() {
      this.groupQuery = '';
      this.proposedGroups = [];
      this.errorFirstname = null;
      this.errorLastname = null;
      this.errorEmail = null;
      this.errorEmptyOrInsufficientPassword = null;
      this.errorPasswordsDontMatch = null;
      this.errorInvalidEmail = null;
      await this.fetchGroups();
      this.showUserModal = true;
      this.updatePermissions();
    },
    updatePermissions() {
      this.permissions.web = this.user != null && this.user.permissions != null && (this.user.permissions & 1) > 0;
      this.permissions.colada = this.user != null && this.user.permissions != null && (this.user.permissions & 4) > 0;
    },
    hide() {
      this.showUserModal = false;
    },
    checkForm() {
      this.showPassword = false;
      this.errorFirstname = null;
      this.errorLastname = null;
      this.errorEmail = null;
      this.errorEmptyOrInsufficientPassword = null;
      this.errorPasswordsDontMatch = null;
      this.errorInvalidEmail = null;

      let hasErrors = false;
      if (this.user.firstname == null || this.user.firstname.trim() === '') {
        this.errorFirstname = this.$t('validationError.firstNameEmpty');
        hasErrors = true;
      }
      if (this.user.lastname == null || this.user.lastname.trim() === '') {
        this.errorLastname = this.$t('validationError.lastNameEmpty');
        hasErrors = true;
      }

      // const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.user.email == null || this.user.email.trim().length < 5) {
        this.errorEmail = this.$t('validationError.usernameTooShort');
        hasErrors = true;
      }
      /* }  else if (!re.test(this.user.email.toLowerCase())) {
        this.errorEmail = this.$t('validationError.invalidEmail');
        hasErrors = true;
      } */

      // in case of a new user, we have to make sure, password is not empty.
      if (this.user._id == null) {
        if (this.user.password == null || this.user.password.trim() === '') {
          this.errorEmptyOrInsufficientPassword = this.$t('validationError.errorEmptyOrInsufficientPassword');
          hasErrors = true;
        } else {
          // check rules
          if (!this.strongRegex.test(this.user.password)) {
            this.errorEmptyOrInsufficientPassword = this.$t('validationError.errorEmptyOrInsufficientPassword');
            hasErrors = true;
          }

          // check cofirmation matches
          if (this.user.password !== this.user.passwordConfirmation) {
            this.errorPasswordsDontMatch = this.$t('validationError.errorPasswordsDontMatch');
            hasErrors = true;
          }
        }
      } else {
        // in case of an existing user, the password can be empty, but we have to check the rules and that the passwords match
        // check rules
        // eslint-disable-next-line no-lonely-if
        if (this.user.password != null && this.user.password.trim() !== '') {
          if (!this.strongRegex.test(this.user.password)) {
            this.errorEmptyOrInsufficientPassword = this.$t('validationError.errorEmptyOrInsufficientPassword');
            hasErrors = true;
          }

          // check cofirmation matches
          if (this.user.password !== this.user.passwordConfirmation) {
            this.errorPasswordsDontMatch = this.$t('validationError.errorPasswordsDontMatch');
            hasErrors = true;
          }
        }
      }
      return !hasErrors;
    },
    async confirm() {
      if (!this.emailChecked) {
        // show alert!
        return;
      }

      if (!this.checkForm()) {
        return;
      }

      // encrypt and send user
      try {
        this.user.hashedEmail = sha256(this.user.email.toLowerCase());
        if (this.user.password != null && this.user.password.trim().length > 0) {
          this.user.hashedPassword = sha256(this.user.password);
        }
        this.user.firstname = CryptoHelper.encrypt(this.user.firstname);
        this.user.lastname = CryptoHelper.encrypt(this.user.lastname);
        this.user.email = CryptoHelper.encrypt(this.user.email);
        this.user.permissions = 0;
        this.user.permissions += this.permissions.web ? 1 : 0;
        this.user.permissions += this.permissions.colada ? 4 : 0;

        // this.user.userGroups = this.user.userGroups.map((g) => ({ _id: g }));
        delete this.user.password;

        if (this.user._id != null) {
          // update
          await ServiceHelper.sendDataToBackend(`user/${this.user._id}`, this.user, 'PUT');
        } else {
          // create
          await ServiceHelper.sendDataToBackend('user', this.user);
        }
        this.$emit('refresh');
      } catch (error) {
        console.error(error);
      }
      this.hide();
    },
    cancel() {
      this.hide();
    },
    async fetchGroups() {
      const data = await ServiceHelper.loadDataFromBackend('userGroups');
      const groupsById = {};
      data.payload.forEach((entry) => {
        if (entry.name != null) {
          const e = entry;
          e.name = CryptoHelper.decrypt(e.name).toString('utf-8');
          groupsById[e._id] = e;
        }
      });
      this.groups = data.payload;
      this.groupsById = groupsById;
      return null;
    },
    proposeGroups() {
      if (this.groupQuery.trim().length === 0) {
        this.proposedGroups = [];
        return;
      }
      const existingIds = {};
      this.user.userGroups.forEach((g) => { existingIds[g] = true; });
      this.proposedGroups = this.groups.filter((g) => g.name.toLowerCase().indexOf(this.groupQuery.toLowerCase()) > -1 && !existingIds[g._id]);
    },
    addGroup(group) {
      this.groupQuery = '';
      this.proposedGroups = false;
      this.user.userGroups.push(group._id);
    },
    removeGroup(group) {
      this.user.userGroups = this.user.userGroups.filter((g) => g !== group);
    },
    async checkEmail() {
      this.emailChecked = false;
      this.errorEmail = null;
      if (this.emailOnEnter != null && this.emailOnEnter === this.user.email.toLowerCase()) {
        this.emailChecked = true;
        this.checkForm();
        return;
      }
      try {
        const data = await ServiceHelper.loadDataFromBackend(`user/checkEmail/${sha256(this.user.email.toLowerCase())}`);
        this.emailChecked = data.payload.emailIsAvailable;
        this.errorEmail = this.emailChecked ? null : this.$t('validationError.usernameNotAvailable');
      } catch (e) {
        console.error(e);
        this.emailChecked = false;
        this.checkForm();
      }
    },
  },
};
</script>

<style scoped>

::v-deep(.modal_content .form .row .tagsInput) {
  width: 100%;
  display: inline-block;
  background-color: white;
  border-bottom: 1px solid lightgray;
  position: relative;
}

::v-deep(.modal_content .form .row .tagsInput .tagSelection) {
  position: absolute;
  max-width: 100%;
  width: 100%;
  top: 0px;
  transform: translateY(-100%);
  transform: translateY(-100%);
  max-height: 150px;
  overflow-y: auto;
  z-index: 9999;
  border: 1px solid gray;
  padding: 2px;
  box-shadow: 4px 4px 8px gray;
  background-color: rgba(255,255,255,0.8);
}

::v-deep(.modal_content .form .row .tagsInput .tagSelection .tagOption) {
  cursor: pointer;
  padding: 4px;
  transition: all ease-in 100ms;
}

::v-deep(.modal_content .form .row .tagsInput .tagSelection .tagOption:hover) {
  background-color: lightsalmon;
}

::v-deep(.modal_content .form .row .tagsInput input) {
  width: 80px;
  border: 0;
}

::v-deep(.modal_content .form .row .tagsInput .tag) {
  font-size: 12px;
  border-radius: 12px;
  line-height: 16px;
  background-color: var(--color_dark_green);
  padding: 4px 24px 4px 8px;
  color: white;
  margin-bottom: 4px;
  margin-right: 4px;
  white-space: nowrap;
  position: relative;
  display: inline-block;
}

::v-deep(.modal_content .form .row .tagsInput .tag .delete) {
  position: absolute;
  line-height: 16px;
  right: 8px;
  top: 3px;
  font-size: 18px;
  cursor: pointer;
}
::v-deep(.modal_content .form .row .tagsInput .tag .delete:hover) {
  font-weight: 800;
}

</style>
